<template>
  <div class="jwl-error">
    <error-ghost class="jwl-error__image no-scale-svg" />

    <div class="jwl-error__headline">
      <slot name="headline">
        {{$t('network.error.errorOccurred')}}
      </slot>
    </div>

    <slot name="error">
      <div class="jwl-error__message" v-if="isHtmlError" v-html="errorMessage" />

      <div class="jwl-error__message" v-else>
        {{errorMessage}}
      </div>

      <div class="jwl-error__message" v-if="errorSubMessage">
        {{errorSubMessage}}
      </div>
    </slot>
  </div>
</template>

<script>
const ErrorGhost = () => import('../assets/error-ghost.svg');

export default {
  name: 'jwl-error',
  components: {
    ErrorGhost,
  },
  props: {
    jwlError: [Object, Error],
  },
  computed: {
    errorMessage () {
      let { message } = this.jwlError;

      if (this.jwlError.trans) {
        message = this.$t(this.jwlError.message);
      } else if (this.jwlError.message.constructor.name === 'Array') {
        message = this.jwlError.message.join(', ');
      } else if (this.jwlError.message.constructor.name === 'Object') {
        const { error } = this.jwlError.message;
        if (error) {
          message = `${error.code}  - ${error.message}`;
        } else {
          const messages = Object.values(this.jwlError.message);
          message = messages.join(', ');
        }
      }

      return message;
    },
    errorSubMessage () {
      if (process.env.NODE_ENV !== 'production') {
        const { error } = this.jwlError.message;
        if (error) {
          return error.exception[0].message;
        }
      }
      return null;
    },
    isHtmlError () {
      return process.env.NODE_ENV !== 'production' && this.errorMessage.startsWith('<!--');
    },
  },
};
</script>

<style lang="scss">
@use "src/styles/variables";
@use "../styles/mixins";

.jwl-error {
  border: .1rem solid var(--color-primary);
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  margin: 2rem 0;
  padding: 3rem 4rem;
  width: 100%;

  &__headline {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    margin-top: auto;
  }

  &__image {
    height: 50%;
    margin: 0 auto 2.5rem;
    width: 50%;

    @include mixins.mq(variables.$mq-phone) {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
